import * as React from 'react';
import Layout from '../../components/Layout';
import { StoreContext } from '../../context/store-context';
import { LineItem } from '../../components/LineItem';
import { format } from '../../utils/format';
import window, { ga4 } from '../../utils/window';
import { calcShippingPrice } from '../../utils/product';

const analytics = ga4();

export default function CartPage() {
    const { checkout, loading } = React.useContext(StoreContext);

    const emptyCart = loading === false && checkout.lineItems.length === 0;

    const shipPrice = !emptyCart && calcShippingPrice(checkout);

    const handleCheckout = () => {
        if (typeof window !== 'undefined' && window.fbq != null) {
            window.fbq('track', 'InitiateCheckout');
        }

        if (analytics !== null) {
            const items = checkout.lineItems.map((item) => (
                {
                    item_id: item.id,
                    item_name: item.title,
                    item_variant: item.variant.title,
                    price: item.variant.priceV2.amount,
                    quantity: item.quantity
                }
            ));
            analytics('event', 'begin_checkout', {
                currency: checkout.totalPriceV2.currencyCode,
                value: checkout.totalPriceV2.amount,
                items
            });
        }

        window.open(checkout.webUrl, '_self');
    };

    if (emptyCart) {
        return (
            <Layout>
                <section className="is-medium section--white">
                    <div className="hero-body">
                        <div className="container">
                            <h2 className="sitteroo-h2 orange">
                                <p className="sitteroo-monday-picnic has-text-centered">
                                    Your cart is empty.
                                </p>
                            </h2>
                        </div>
                        <div className="container">
                            <p className="subtitle orange has-text-centered is-desktop pt-5 pb-5">
                                <a href="/shop/products/" className="brown">
                                    Start again
                                </a>{' '}
                                and design your unforgettable lifetime artwork
                            </p>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }

    return (
        <Layout>
            <section className="is-medium section--white">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns is-2-desktop">
                            <div className="column is-two-thirds">
                                <h2 className="sitteroo-h2 orange">
                                    <p className="sitteroo-monday-picnic">
                                        Shopping Cart
                                    </p>
                                </h2>
                                <div className="table-container">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <p className="is-sr-only">
                                                        Product Name
                                                    </p>
                                                </th>
                                                <th>
                                                    <p className="is-sr-only">
                                                        Options
                                                    </p>
                                                </th>
                                                <th>
                                                    <p className="is-sr-only">
                                                        Price
                                                    </p>
                                                </th>
                                                <th>
                                                    <p className="is-sr-only">
                                                        Remove
                                                    </p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checkout.lineItems.map((item) => (
                                                <LineItem
                                                    item={item}
                                                    key={`item-${item.id}`}
                                                />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="column">
                                <h2 className="sitteroo-h2 orange">
                                    <p className="sitteroo-monday-picnic">
                                        Summary
                                    </p>
                                </h2>
                                <table className="table mb-6">
                                    <thead>
                                        <tr>
                                            <th>
                                                <p className="is-sr-only">
                                                    Description
                                                </p>
                                            </th>
                                            <th>
                                                <p className="is-sr-only">
                                                    Amount
                                                </p>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="summary">
                                            <td className="is-size-5">
                                                Subtotal:
                                            </td>
                                            <td className="is-size-5">
                                                {format(
                                                    checkout.subtotalPriceV2
                                                        .currencyCode,
                                                    checkout.subtotalPriceV2
                                                        .amount
                                                )}
                                            </td>
                                        </tr>
                                        <tr className="summary">
                                            <td className="is-size-5">
                                                Shipping:
                                            </td>
                                            <td className="is-size-5">
                                                {shipPrice === 0 ? 'Free' : format(
                                                    checkout.totalPriceV2.currencyCode,
                                                    shipPrice
                                                ) }
                                            </td>
                                        </tr>
                                        <tr className="grandTotal">
                                            <td className="is-size-5">
                                                Total Price:
                                            </td>
                                            <td className="is-size-5">
                                                {format(
                                                    checkout.totalPriceV2.currencyCode,
                                                    (checkout.totalPriceV2.amount*1 + shipPrice)
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button
                                    type="button"
                                    onClick={handleCheckout}
                                    disabled={loading}
                                    className="sitteroo-button bg-salmon is-fullwidth"
                                >
                                    Checkout
                                </button>
                                <div className="is-clearfix" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
