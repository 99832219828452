import React, { useContext } from 'react';
import { upperFirst } from 'lodash';
import { StoreContext } from '../context/store-context';
import { format } from '../utils/format';

export function LineItem({ item }) {
    const { removeLineItem, checkout } = useContext(StoreContext);

    const subtotal = format(
        item.variant.priceV2.currencyCode,
        Number(item.variant.priceV2.amount) * item.quantity
    );

    const handleRemove = () => {
        removeLineItem(checkout.id, item.id);
    };

    const mapKey = (key) => {
        if (key === 'pob') {
            return 'Place of birth';
        }
        if (key === 'show_constellations') {
            return 'Constellations';
        }
        if (key === 'dob') {
            return 'Date of birth';
        }
        if (key === 'tob') {
            return 'Time of birth';
        }

        return upperFirst(key);
    };

    return (
        <tr>
            <td className="is-size-5">
                {item.quantity} x {item.title}
            </td>
            <td>
                {item.variant.title !== 'Default Title' ? `Type: ${item.variant.title}` : ''}
                {item.customAttributes.map((attribute) => (
                    <p key={attribute.key}>
                        {mapKey(attribute.key)}: {attribute.value}
                    </p>
                ))}
            </td>
            <td className="is-size-5">{subtotal}</td>
            <td className="is-size-5">
                <button
                    type="button"
                    onClick={handleRemove}
                    className="delete is-medium"
                >
                    <span>Delete</span>
                </button>
            </td>
        </tr>
    );
}
